.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#loginBody {
  background: -webkit-linear-gradient(to right, #796dfe, #8b80ff);
  background: linear-gradient(to right, #796dfe, #8b80ff);
  height: 100vh;
}

#messageCategoriesBar {
  height: 95.1vh;
  min-width: 140px;
  background-color: #283042;
  border-right: #50596b solid 1px;
}

#messagesMainDiv {
  height: 92.8%;
  width: 100%;
}

#messageThreadsBar {
  height: 95.1vh;
  min-width: 210px;
  background-color: #283042;
  border-right: #50596b solid 1px;
  overflow-y: scroll;
}

#messagesInnerMainDiv {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
}

#viewMessageMainDiv {
  display: flex;
  flex-direction: row;
  background-color: #eef3f8;
  width: 100%;
}

#viewMessageSideBar {
  height: 95.2vh;
  width: 20.8%;
  background-color: #283042;
  border-left: #50596b solid 1px;
  align-self: flex-end;
  margin-bottom: -4px;
  display: flex;
}

#viewMessageMainScrollDiv::-webkit-scrollbar {
  display: none;
}

#viewMessageTypeMessageDiv {
  align-self: center;
  width: 80%;
  min-height: 50px;
  max-height: 130px;
  height: auto;
  border: #000 solid 1px;
  margin-top: 6%;
  border-radius: 5px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
}

#viewMessageTypeMessageDivInput {
  width: 93%;
  border: 0px;
  max-height: 110px;
  min-height: 30px;
  margin-left: 2%;
  resize: none;
  overflow-y: hidden;
}

#viewMessageTypeMessageDivInput:focus {
  outline: none;
}

#viewMessageTypeMessageDivInputIcon {
  margin-left: 4%;
  cursor: pointer;
}

.routeBuilderEditSideMenuScroll::-webkit-scrollbar {
  display: none;
}

#calendarStripMainDiv {
  display: flex;
  width: 99.8%;
  border-bottom: #50596b 1px solid;
  height: 68px;
  background-color: #fff;
  overflow: scroll;
  overflow-y: hidden;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -10px;
}

#calendarStripRouteBuilderMainDiv {
  display: flex;
  height: 60px;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  align-self: center;
  flex-direction: row;
  margin-top: 18px;
  margin-bottom: 16px;
}

#calendarStripRouteBuilderMainDiv::-webkit-scrollbar {
  display: none;
}

#calendarStripMainDiv::-webkit-scrollbar {
  display: none;
}

.hideScroll::-webkit-scrollbar {
  display: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
}

input[type="file"] {
  display: none;
}

tbody {
  display: block;
  height: 68vh;
  overflow: auto;
}
thead,
tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed; /* even columns width , fix width of table too*/
}
thead {
  width: calc(
    100% - 1em
  ); /* scrollbar is average 1em/16px width, remove it from thead width */
}
table {
  width: 400px;
}

.customer-profile-tbody {
  display: block;
  height: 220px !important;
  overflow: auto;
}
thead,
.customer-profile-tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed; /* even columns width , fix width of table too*/
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.changesPlannerDatePicker::-webkit-calendar-picker-indicator {
  position: relative;
  top: -6px;
}
